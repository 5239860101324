import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { filter, Observable, tap } from 'rxjs';
import { DougsDatePipe, LoaderComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { VendorInvoice, VendorInvoicePrefillStatus } from '@dougs/vendor-invoice/dto';
import { ShowVendorInvoiceDividerPipe } from '../../pipes/show-vendor-invoice-divider.pipe';
import { VendorInvoiceAddComponentService } from '../../services/list/vendor-invoice-add.component.service';
import { VendorInvoiceListComponentService } from '../../services/list/vendor-invoice-list.component.service';
import { OpenVendorInvoiceDrawerService } from '../../services/open-vendor-invoice-drawer.service';
import { VendorInvoiceItemComponent } from './vendor-invoice-item/vendor-invoice-item.component';

const ANIMATION = '200ms ease-in-out';

@Component({
  selector: 'dougs-vendor-invoice-list',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    VendorInvoiceItemComponent,
    ShowVendorInvoiceDividerPipe,
    DougsDatePipe,
    InfiniteScrollDirective,
  ],
  templateUrl: './vendor-invoice-list.component.html',
  styleUrls: ['./vendor-invoice-list.component.scss', '../../scss/vendor-invoice-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', overflow: 'hidden', paddingTop: 0, paddingBottom: 0 }),
        animate(ANIMATION, style({ height: '*', paddingTop: '*', paddingBottom: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden', paddingTop: '*', paddingBottom: '*' }),
        animate(ANIMATION, style({ height: 0, paddingTop: 0, paddingBottom: 0 })),
      ]),
    ]),
    trigger('slideAfterLoading', [
      transition('true => false', [
        style({ height: '0', overflow: 'hidden', paddingTop: 0, paddingBottom: 0 }),
        animate(ANIMATION, style({ height: '*', paddingTop: '*', paddingBottom: '*' })),
      ]),
    ]),
  ],
})
export class VendorInvoiceListComponent {
  private _isInModal = false;

  get isInModal(): boolean {
    return this._isInModal;
  }

  @Input()
  set isInModal(isInModal: boolean) {
    this._isInModal = isInModal;
    this.vendorInvoiceListComponentService.setIsInModal(isInModal);
    if (this.isInModal) {
      this.isReducedList = false;
    }
  }

  VENDOR_INVOICE_PREFILL_STATUS = VendorInvoicePrefillStatus;
  isReducedList = true;

  openUnpaidList$: Observable<void> = this.vendorInvoiceAddComponentService.vendorInvoiceCreated$.pipe(
    filter(() => !this.isPaidList),
    tap(() => (this.isReducedList = false)),
  );

  readonly SIZE_LIST = 4;
  @Input() isSearching = false;
  @Input() vendorInvoices: VendorInvoice[] = [];
  @Input() operation!: Operation | null;
  @Input() isPaidList = false;
  @Input() noMargin = false;

  @Output() vendorInvoiceAttached: EventEmitter<VendorInvoice> = new EventEmitter<VendorInvoice>();
  @Output() vendorInvoiceDetached: EventEmitter<VendorInvoice> = new EventEmitter<VendorInvoice>();

  constructor(
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly vendorInvoiceListComponentService: VendorInvoiceListComponentService,
    public readonly vendorInvoiceAddComponentService: VendorInvoiceAddComponentService,
    public readonly openVendorInvoiceDrawerService: OpenVendorInvoiceDrawerService,
  ) {}

  trackById(index: number, vendorInvoice: VendorInvoice): string {
    return vendorInvoice.id;
  }
}
