<ng-container *ngIf="openUnpaidList$ | async"></ng-container>
<div
  class="vendor-invoice-list"
  infiniteScroll
  [infiniteScrollDisabled]="isInModal"
  [immediateCheck]="true"
  (scrolled)="vendorInvoiceListComponentService.loadMoreVendorInvoice(isPaidList, isReducedList)"
>
  <div class="vendor-invoice-list__title mb-16" *ngIf="!isInModal && !isSearching">
    <h2>
      <ng-content></ng-content>
    </h2>
    <p *ngIf="vendorInvoices?.length > SIZE_LIST" class="tiny pointer" (click)="isReducedList = !isReducedList">
      {{ isReducedList ? 'Agrandir' : 'Réduire' }}
      <i class="fal ml-4" [ngClass]="{ 'fa-chevron-down': isReducedList, 'fa-chevron-up': !isReducedList }"></i>
    </p>
  </div>
  <div *ngIf="vendorInvoices?.length" [@slideInOut]>
    <div
      class="vendor-invoice-list__header px-24 py-8"
      [ngClass]="{ 'vendor-invoice-list__header__in-modal': isInModal }"
    >
      <p class="tiny bold color-primary-700">Date d’émission</p>
      <p class="tiny bold color-primary-700">Fournisseur</p>
      <p class="tiny bold color-primary-700 ml-8">Opération</p>
      <p class="tiny bold color-primary-700" *ngIf="isInModal">Statut</p>
      <p class="tiny bold color-primary-700 self-right" [ngClass]="{ 'amount-in-modal': isInModal }">Montant</p>
      <p class="tiny bold color-primary-700" *ngIf="!isInModal">Statut</p>
    </div>
    <ng-container
      *ngFor="
        let vendorInvoice of isReducedList ? (vendorInvoices | slice: 0 : SIZE_LIST) : vendorInvoices;
        let index = index;
        let isFirst = first;
        trackBy: trackById
      "
    >
      <div
        [@slideInOut]
        [@slideAfterLoading]="vendorInvoice.prefillStatus === VENDOR_INVOICE_PREFILL_STATUS.INITIALISED"
        *ngIf="vendorInvoices | showVendorInvoiceDivider: index : isFirst"
        class="px-24 py-8 operation-list__date left month color-gray-350"
        [ngStyle]="{ top: isInModal ? '50px' : '60px' }"
      >
        {{ vendorInvoice.date | date: 'MMMM yyyy' | titlecase }}
      </div>
      <dougs-vendor-invoice-item
        [@slideInOut]
        [@slideAfterLoading]="vendorInvoice.prefillStatus === VENDOR_INVOICE_PREFILL_STATUS.INITIALISED"
        (click)="!isInModal && openVendorInvoiceDrawerService.openVendorInvoiceDrawer(vendorInvoice)"
        [vendorInvoice]="vendorInvoice"
        [operation]="operation"
        [isInModal]="isInModal"
        (vendorInvoiceAttached)="vendorInvoiceAttached.emit($event)"
        (vendorInvoiceDetached)="vendorInvoiceDetached.emit($event)"
      ></dougs-vendor-invoice-item>
    </ng-container>
  </div>
  <div
    class="vendor-invoice-list__footer pointer px-16 py-8"
    (click)="isReducedList = false"
    *ngIf="!isSearching && isReducedList && vendorInvoices.length > SIZE_LIST"
  >
    <p class="tiny">
      Tout afficher
      <i class="fal fa-chevron-down ml-4"></i>
    </p>
  </div>
</div>
<div
  *ngIf="!isSearching && vendorInvoices.length === 0"
  class="vendor-invoice-list__blank-slate p-8"
  [ngClass]="{ 'is-in-modal': isInModal }"
  [@slideInOut]
>
  <p *ngIf="isPaidList" class="tiny">Vous n'avez aucune facture payée.</p>
  <p *ngIf="!isPaidList" class="tiny">
    Vous n'avez aucune facture à payer
    <img class="mr-16 ml-8" src="images/emoji/party-popper.png" alt="Fiesta" />
  </p>
</div>
