import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-list-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListLoadingComponent {
  @Input() first = false;
}
