import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, withLatestFrom } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService } from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { TreasuryStateService } from '@dougs/synchronized-accounts/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService, VendorInvoiceStatsStateService } from '@dougs/vendor-invoice/shared';
import { VendorInvoiceListComponentService } from './list/vendor-invoice-list.component.service';
import { VendorInvoiceSearchComponentService } from './list/vendor-invoice-search.component.service';
import { OpenVendorInvoiceDrawerService } from './open-vendor-invoice-drawer.service';

@Injectable()
export class VendorInvoiceComponentService {
  constructor(
    private readonly vendorInvoiceSearchComponentService: VendorInvoiceSearchComponentService,
    private readonly vendorInvoiceListComponentService: VendorInvoiceListComponentService,
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyDemoStateService: CompanyDemoStateService,
    private readonly openVendorInvoiceDrawerService: OpenVendorInvoiceDrawerService,
    private readonly vendorInvoiceStatsStateService: VendorInvoiceStatsStateService,
    private readonly treasuryStateService: TreasuryStateService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  isLoading$: Observable<boolean> = combineLatest([
    this.vendorInvoiceSearchComponentService.isSearching$,
    this.vendorInvoiceListComponentService.isLoading$,
  ]).pipe(map(([isSearching, isLoading]) => isSearching || isLoading));

  shouldShowUnpaidInvoicesList$: Observable<boolean> = combineLatest([
    this.vendorInvoiceStateService.notPaidVendorInvoices$,
    this.vendorInvoiceSearchComponentService.hasCurrentSearch$,
  ]).pipe(map(this.shouldShowInvoiceList));

  shouldShowPaidInvoicesList$: Observable<boolean> = combineLatest([
    this.vendorInvoiceStateService.paidVendorInvoices$,
    this.vendorInvoiceSearchComponentService.hasCurrentSearch$,
  ]).pipe(map(this.shouldShowInvoiceList));

  refreshOnVendorInvoiceDrawerClosed$: Observable<void[]> =
    this.openVendorInvoiceDrawerService.vendorInvoiceClosed$.pipe(
      concatMap(() =>
        Promise.all([
          this.vendorInvoiceStatsStateService.refreshVendorInvoiceStats(this.companyStateService.activeCompany.id),
          this.companyServicesStateService.services?.accounting.shouldShowModuleLink
            ? this.treasuryStateService.refreshAccountBalances(this.companyStateService.activeCompany.id)
            : Promise.resolve(undefined),
        ]),
      ),
    );

  triggerWarningDemoMode$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    withLatestFrom(this.companyDemoStateService.hasDemoMode$),
    filter(([_, hasDemoMode]) => hasDemoMode),
    map(() => this.triggerWarningIfDemoMode()),
  );

  triggerWarningIfDemoMode(): void {
    this.flashMessagesService.show(
      "L'onglet \"<b>Factures d'achat</b>\" n'est pas impacté par le compte de démonstration.<br>" +
        'Les <b>informations saisies</b> ici sont vos <b>données réelles</b> qui seront conservées.',
      {
        type: 'warning',
        timeout: 15000,
      },
    );
  }

  private shouldShowInvoiceList([invoices, hasCurrentSearch]: [VendorInvoice[], boolean]): boolean {
    return invoices.length > 0 || !hasCurrentSearch;
  }
}
