<ng-container *ngIf="vendorInvoiceItemComponentService.memoFormControlValueChanges$ | async"></ng-container>
<div class="vendor-invoice-line__container">
  <div
    *ngIf="vendorInvoice.prefillStatus !== VENDOR_INVOICE_PREFILL_STATUS.INITIALISED"
    class="vendor-invoice-line pointer px-24 py-8"
    [ngClass]="{ 'is-in-modal': isInModal }"
    (mouseenter)="vendorInvoiceItemComponentService.setIsHover(true)"
    (mouseleave)="vendorInvoiceItemComponentService.setIsHover(false)"
  >
    <div class="vendor-invoice-title">
      <h4 class="vendor-invoice-title__date">
        <ng-container *ngIf="vendorInvoice.date; else dateBlankslate">
          <span>{{ vendorInvoice.date | date: 'dd' }}</span>
          <span class="vendor-invoice-title__date__month">
            {{ vendorInvoice.date | date: 'MMM' | slice: 0 : 4 }}
          </span>
        </ng-container>
        <ng-template #dateBlankslate>-</ng-template>
      </h4>
      <div class="vendor-invoice-title__label">
        <p class="tiny color-primary-700">{{ vendorInvoice.label }}</p>
        <dougs-memo
          (click)="$event.stopPropagation()"
          [light]="true"
          [formControl]="vendorInvoiceItemComponentService.memoFormControl"
        ></dougs-memo>
      </div>
    </div>
    <p class="supplier tiny color-primary-700">
      {{ vendorInvoice?.supplierName ?? '-' }}
    </p>
    <div *ngIf="(companyServicesStateService.services$ | async)?.accounting.shouldShowModuleLink" class="tiny">
      <dougs-pill class="ml-4" *ngIf="vendorInvoice.operations.length > 0">
        {{ vendorInvoice.operations.length }}
        <ng-container [ngPlural]="vendorInvoice.operations.length">
          <ng-template ngPluralCase="=1">opération</ng-template>
          <ng-template ngPluralCase="other">opérations</ng-template>
        </ng-container>
      </dougs-pill>
      <dougs-pill
        class="ml-4"
        type="yellow"
        *ngIf="!vendorInvoice?.operations?.length && vendorInvoice.matchedOperation"
      >
        <i class="sparkles-icon fal fa-sparkles color-yellow-500 mr-4"></i>
        1 opération
      </dougs-pill>
    </div>
    <div *ngIf="isInModal">
      <dougs-pill *ngIf="vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.PAID" type="success">
        <div class="pill-icon__container color-green-700">
          <i class="fal fa-check pill-icon"></i>
          <p class="xtiny">Payée</p>
        </div>
      </dougs-pill>
      <dougs-pill *ngIf="vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.NOT_PAID" type="primary">
        <div class="pill-icon__container color-primary-700">
          <i class="fal fa-xmark pill-icon"></i>
          <p class="xtiny">Non payée</p>
        </div>
      </dougs-pill>
      <dougs-pill *ngIf="vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.PARTIALLY_PAID" type="warning">
        <div class="pill-icon__container color-yellow-700">
          <i class="fal fa-hourglass-clock pill-icon"></i>
          <p class="xtiny">Partiellement payée</p>
        </div>
      </dougs-pill>
    </div>
    <p
      class="self-right tiny bold"
      [ngClass]="{ 'color-primary': vendorInvoice.isRefund, 'color-primary-700': !vendorInvoice.isRefund }"
    >
      <ng-container *ngIf="vendorInvoice.currency | currencyClass; else noCurrencySymbol">
        {{
          vendorInvoice.amount
            ? (vendorInvoice.amount | mathAbs | currency: vendorInvoice?.currency || 'EUR' : 'symbol-narrow' : '0.2-2')
            : '-'
        }}
      </ng-container>
      <ng-template #noCurrencySymbol>
        {{ vendorInvoice.amount ? (vendorInvoice.amount | mathAbs | number: '0.2-2') : '-' }}
        {{ vendorInvoice.currency }}
      </ng-template>
    </p>
    <div class="cta" *ngIf="!isInModal">
      <dougs-button
        (click)="$event.stopPropagation(); vendorInvoiceItemComponentService.markVendorInvoiceAsPaid(vendorInvoice)"
        *ngIf="
          !isPaid && vendorInvoiceItemComponentService.isHover$() && !vendorInvoiceItemComponentService.isAccrualInvoice
        "
        color="secondary"
        size="small"
      >
        <i class="fal fa-check mr-4"></i>
        Marquer payée
      </dougs-button>
      <dougs-pill *ngIf="vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.PAID" type="success">
        <div class="pill-icon__container color-green-700">
          <i class="fal fa-check pill-icon"></i>
          <p class="xtiny">Payée</p>
        </div>
      </dougs-pill>
      <dougs-pill
        *ngIf="
          vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.NOT_PAID &&
          (!vendorInvoiceItemComponentService.isHover$() || vendorInvoiceItemComponentService.isAccrualInvoice)
        "
        type="primary"
      >
        <div class="pill-icon__container color-primary-700">
          <i class="fal fa-xmark pill-icon"></i>
          <p class="xtiny">Non payée</p>
        </div>
      </dougs-pill>
      <dougs-pill
        *ngIf="
          vendorInvoice.paymentStatus === VendorInvoicePaymentStatus.PARTIALLY_PAID &&
          (!vendorInvoiceItemComponentService.isHover$() || vendorInvoiceItemComponentService.isAccrualInvoice)
        "
        type="warning"
      >
        <div class="pill-icon__container color-yellow-700">
          <i class="fal fa-hourglass-clock pill-icon"></i>
          <p class="xtiny">Partiellement payée</p>
        </div>
      </dougs-pill>
    </div>
    <dougs-icon-button
      *ngIf="!isInModal"
      iconClass="ellipsis-v"
      color="ghost"
      type="regular"
      (click)="$event.stopPropagation()"
      [dougsDropdownTrigger]="dropdownActions"
    />
    <dougs-button
      *ngIf="!operation && isInModal"
      [fullWidth]="true"
      size="small"
      (click)="
        vendorInvoiceOperationComponentService.isOperationAttached$() ? detachTempOperation() : attachTempOperation()
      "
      [color]="vendorInvoiceOperationComponentService.isOperationAttached$() ? 'secondary' : 'primary'"
    >
      {{ vendorInvoiceOperationComponentService.isOperationAttached$() ? 'Détacher' : 'Attacher' }}
    </dougs-button>
    <dougs-button
      *ngIf="operation && isInModal"
      [fullWidth]="true"
      size="small"
      (click)="
        vendorInvoiceOperationComponentService.isOperationAttached$()
          ? vendorInvoiceOperationComponentService.detachOperation($event, operation, vendorInvoice, isInModal)
          : vendorInvoiceOperationComponentService.attachOperation($event, operation, vendorInvoice, isInModal)
      "
      [color]="vendorInvoiceOperationComponentService.isOperationAttached$() ? 'secondary' : 'primary'"
    >
      {{ vendorInvoiceOperationComponentService.isOperationAttached$() ? 'Détacher' : 'Attacher' }}
    </dougs-button>
  </div>
  <div
    *ngIf="vendorInvoice.prefillStatus === VENDOR_INVOICE_PREFILL_STATUS.INITIALISED"
    class="vendor-invoice-line-loading pointer px-24 py-8"
  >
    <div class="vendor-invoice-title">
      <div class="vendor-invoice-title__spinner__container">
        <i class="vendor-invoice-title__spinner fad fa-spinner-third fa-spin fa-lg"></i>
      </div>
      <div class="vendor-invoice-title__label">
        <p class="tiny color-primary-700">{{ vendorInvoice.label }}</p>
        <p class="tiny">Récupération des informations de la facture en cours..</p>
      </div>
    </div>
    <div class="vendor-invoice-title__skeleton">
      <div class="skeleton subtitle"></div>
      <div class="skeleton button"></div>
    </div>
  </div>
</div>
<dougs-dropdown #dropdownActions [displayArrow]="false" [widthToRefElement]="false">
  <dougs-dropdown-option [closeOnClick]="true">
    <a [href]="vendorInvoice.filePath | trustRessourceUrl" target="_blank">
      <i class="fal fa-file-download mr-8"></i>
      Télécharger
    </a>
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="vendorInvoiceActionsComponentService.renameVendorInvoice(vendorInvoice)"
    [closeOnClick]="true"
  >
    <i class="fal fa-pen mr-8"></i>
    Renommer
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="vendorInvoiceActionsComponentService.deleteVendorInvoice(vendorInvoice)"
    [closeOnClick]="true"
  >
    <span class="color-error">
      <i class="fal fa-trash mr-8"></i>
      Supprimer
    </span>
  </dougs-dropdown-option>
</dougs-dropdown>
