import { Injectable, Optional } from '@angular/core';
import { first, lastValueFrom, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { FlashMessagesService } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { OpenVendorInvoiceDrawerService } from '../open-vendor-invoice-drawer.service';
import { VendorInvoiceSearchComponentService } from './vendor-invoice-search.component.service';
import { VendorInvoiceStatsComponentService } from './vendor-invoice-stats.component.service';

@Injectable()
export class VendorInvoiceAddComponentService {
  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
    private readonly vendorInvoiceSearchComponentService: VendorInvoiceSearchComponentService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly openVendorInvoiceDrawerService: OpenVendorInvoiceDrawerService,
    @Optional() private readonly vendorInvoiceStatsComponentService: VendorInvoiceStatsComponentService,
  ) {}

  isInternalAccountingFirmUser$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  private readonly vendorInvoiceCreatedSubject: Subject<void> = new Subject<void>();
  public vendorInvoiceCreated$: Observable<void> = this.vendorInvoiceCreatedSubject.asObservable();

  async createVendorInvoice(e: FileList, fromBlankSlate = false, fromDragAndDrop?: boolean): Promise<void> {
    this.vendorInvoiceCreatedSubject.next();
    const vendorInvoices: VendorInvoice[] =
      (
        await Promise.all(
          (Array.from(e) as File[]).map((file) =>
            this.vendorInvoiceStateService.createVendorInvoice(this.companyStateService.activeCompany.id, file),
          ),
        )
      )?.filter((invoice: VendorInvoice | null): invoice is VendorInvoice => invoice !== null) || [];
    this.handleAlreadyUploadedVendorInvoices(vendorInvoices);
    if (await lastValueFrom(this.vendorInvoiceSearchComponentService.hasCurrentSearch$.pipe(first()))) {
      this.vendorInvoiceSearchComponentService.clearSearch();
    }

    if (vendorInvoices?.length) {
      await this.vendorInvoiceStatsComponentService?.refreshStats();
      this.metricsService.pushMixpanelEvent('Supplier Invoicing Invoice Created', {
        'CTA Location': fromBlankSlate
          ? 'First Invoice'
          : fromDragAndDrop
            ? 'Drag and Drop'
            : 'Add supplier invoice button',
        Module: 'Supplier Invoice Module',
        'File Number': vendorInvoices.length,
      });
      const newVendorInvoices: VendorInvoice[] = vendorInvoices.filter(
        (vendorInvoice) => !vendorInvoice.hasAlreadyBeenUploaded,
      );
      if (newVendorInvoices?.length) {
        const flashMessage = `${newVendorInvoices.length > 1 ? newVendorInvoices.length : ''} ${
          newVendorInvoices.length > 1 ? 'f' : 'F'
        }acture${newVendorInvoices.length > 1 ? 's' : ''} ajoutée${newVendorInvoices.length > 1 ? 's' : ''}`;
        this.flashMessagesService.show(flashMessage, {
          type: 'success',
          timeout: 2000,
        });
      }
      if (vendorInvoices.length === 1) {
        await this.openVendorInvoiceDrawerService.openVendorInvoiceDrawer(vendorInvoices[0]);
      }
    }
  }

  private handleAlreadyUploadedVendorInvoices(vendorInvoices: VendorInvoice[]): void {
    const alreadyUploadedVendorInvoices: VendorInvoice[] = vendorInvoices.filter(
      (vendorInvoice) => vendorInvoice.hasAlreadyBeenUploaded,
    );
    if (alreadyUploadedVendorInvoices?.length) {
      const flashMessage: string = this.getDuplicateMessage(alreadyUploadedVendorInvoices.length);
      this.flashMessagesService.show(flashMessage, { timeout: 5000 });
    }
  }

  private getDuplicateMessage(alreadyUploadedInvoiceNbr: number): string {
    if (alreadyUploadedInvoiceNbr === 1) {
      return `Cette facture a déjà été ajoutée dans votre module Factures d'achat.`;
    }
    return `${alreadyUploadedInvoiceNbr} de ces factures ont déjà été ajoutées dans votre module Factures d'achat.`;
  }
}
