import { Pipe, PipeTransform } from '@angular/core';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

@Pipe({
  name: 'showVendorInvoiceDivider',
  standalone: true,
})
export class ShowVendorInvoiceDividerPipe implements PipeTransform {
  transform(vendorInvoices: VendorInvoice[], index: number, isFirst: boolean): boolean {
    const initialPreviousDate: string | Date | undefined = vendorInvoices[index - 1]?.date;
    const initialCurrentDate: string | Date | undefined = vendorInvoices[index]?.date;

    if (!initialCurrentDate) {
      return false;
    }
    if (isFirst || !initialPreviousDate) {
      return true;
    }
    const previousDate: Date = new Date(initialPreviousDate);
    const currentDate: Date = new Date(initialCurrentDate);

    return (
      previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth()
    );
  }
}
