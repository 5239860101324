import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, concatMap, filter, Observable, ReplaySubject, Subject, tap, withLatestFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';

@Injectable()
export class VendorInvoiceSearchComponentService {
  search: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  searchValue?: string;
  private readonly hasCurrentSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasCurrentSearch$: Observable<boolean> = this.hasCurrentSearchSubject.asObservable();
  private readonly isSearching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSearching$: Observable<boolean> = this.isSearching.asObservable();

  private readonly searchValueSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  searchValue$: Observable<string> = this.searchValueSubject.asObservable();

  search$: Observable<string> = this.search.valueChanges.pipe(
    debounceTime(500),
    tap((search: string) => this.searchValueSubject.next(search)),
  );

  private readonly resetOffset: Subject<void> = new Subject<void>();
  resetOffset$: Observable<void> = this.resetOffset.asObservable();

  refreshOnSearch$: Observable<[void, void]> = this.searchValue$.pipe(
    filter((search: string) => this.searchValue !== search),
    tap((search) => (this.searchValue = search)),
    tap(() => this.isSearching.next(true)),
    tap((search) => this.hasCurrentSearchSubject.next(search !== '')),
    tap(() => this.resetOffset.next()),
    withLatestFrom(this.companyStateService.activeCompany$),
    concatMap(([search, activeCompany]) =>
      Promise.all([
        this.vendorInvoiceStateService.refreshPaidVendorInvoices(activeCompany.id, search),
        this.vendorInvoiceStateService.refreshNotPaidVendorInvoices(activeCompany.id, search),
      ]),
    ),
    tap(() => this.isSearching.next(false)),
  );

  clearSearchOnUserChanges$: Observable<Company> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.clearSearchWithoutRefresh()),
  );

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
  ) {}

  clearSearch(): void {
    this.search.setValue('', { emitEvent: false });
    this.searchValueSubject.next('');
  }

  clearSearchWithoutRefresh(): void {
    this.search.setValue('', { emitEvent: false });
    this.searchValue = '';
    this.hasCurrentSearchSubject.next(false);
  }
}
