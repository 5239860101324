<div class="list-loading" [ngClass]="{ 'mt-40': !first }">
  <div class="skeleton title ellipse" [ngStyle]="{ width: '270px' }"></div>
  <div class="table mt-16">
    <table>
      <thead>
        <tr [ngStyle]="{ height: '40px' }">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of [1, 2, 3]">
          <td>
            <div class="skeleton subtitle ellipse my-16" [ngStyle]="{ width: '50px' }"></div>
          </td>
          <td>
            <div class="skeleton subtitle ellipse my-16" [ngStyle]="{ width: '210px' }"></div>
          </td>
          <td>
            <div class="skeleton subtitle ellipse my-16" [ngStyle]="{ width: '300px' }"></div>
          </td>
          <td>
            <div class="skeleton subtitle ellipse my-16" [ngStyle]="{ width: '70px' }"></div>
          </td>
          <td>
            <div class="skeleton subtitle ellipse my-12" [ngStyle]="{ width: '130px' }"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
