import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject, tap } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { QueryParamsRoutingService } from '@dougs/core/routing';
import { DrawerService } from '@dougs/ds';
import { VendorInvoiceOperationsStateService } from '@dougs/operations/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

@Injectable({ providedIn: 'root' })
export class OpenVendorInvoiceDrawerService {
  constructor(
    private readonly drawerService: DrawerService,
    private readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    private readonly queryParamsRoutingService: QueryParamsRoutingService,
    private readonly metricsService: MetricsService,
  ) {}

  private readonly vendorInvoiceClosedSubject: Subject<void> = new Subject<void>();
  public readonly vendorInvoiceClosed$: Observable<void> = this.vendorInvoiceClosedSubject.asObservable();

  async openVendorInvoiceDrawer(vendorInvoice: VendorInvoice): Promise<void> {
    this.metricsService.pushMixpanelEvent('Vendor Invoice Drawer Opened', {
      Module: 'Vendor Invoice',
    });
    await this.queryParamsRoutingService.updateQueryParams('vendorInvoiceId', vendorInvoice?.id ?? null);
  }

  async openVendorInvoiceDrawerFromRouter(vendorInvoice: VendorInvoice): Promise<void> {
    const { VendorInvoiceModalComponent } = await import(
      '../modals/vendor-invoice-modal/vendor-invoice-modal.component'
    );
    await lastValueFrom(
      this.drawerService
        .open(VendorInvoiceModalComponent, {
          data: vendorInvoice,
        })
        .afterClosed$.pipe(
          tap(() => this.vendorInvoiceClosedSubject.next()),
          tap(() => this.vendorInvoiceOperationsStateService.resetState()),
        ),
    );
  }
}
