import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, map, Observable, startWith, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { VendorInvoiceSearchComponentService } from './vendor-invoice-search.component.service';

@Injectable()
export class VendorInvoiceListComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly queueScroll: Subject<boolean> = new Subject<boolean>();
  queueScroll$: Observable<boolean> = this.queueScroll.asObservable();

  private readonly isInModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInModal$: Observable<boolean> = this.isInModal.asObservable();

  paidOffset = 0;
  notPaidOffset = 0;

  refreshVendorInvoices$: Observable<[void, void]> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.next(true)),
    tap(() => this.resetOffset()),
    concatMap((activeCompany: Company) =>
      Promise.all([
        this.vendorInvoiceStateService.refreshPaidVendorInvoices(activeCompany.id),
        this.vendorInvoiceStateService.refreshNotPaidVendorInvoices(activeCompany.id),
      ]),
    ),
    tap(() => this.isLoading.next(false)),
  );

  loadMoreVendorInvoice(isPaid: boolean, isListReduced: boolean): void {
    if (!isListReduced) {
      if (this.isInModal.value) {
        this.paidOffset++;
        this.notPaidOffset++;
      } else if (isPaid) {
        this.paidOffset++;
      } else {
        this.notPaidOffset++;
      }
      this.queueScroll.next(isPaid);
    }
  }

  setIsInModal(isInModal: boolean): void {
    this.isInModal.next(isInModal);
  }

  resetOffset(): void {
    this.paidOffset = 0;
    this.notPaidOffset = 0;
  }

  resetOffset$: Observable<void> = this.vendorInvoiceSearchComponentService.resetOffset$.pipe(
    map(() => this.resetOffset()),
  );

  loadMoreVendorInvoice$: Observable<void | [void, void]> = this.queueScroll$.pipe(
    withLatestFrom(
      this.companyStateService.activeCompanyIdChanged$,
      this.vendorInvoiceSearchComponentService.searchValue$.pipe(startWith('')),
    ),
    switchMap(([isPaid, company, search]: [boolean, Company, string | undefined]) =>
      this.isInModal.value
        ? Promise.all([
            this.vendorInvoiceStateService.refreshPaidVendorInvoices(company.id, search, this.paidOffset),
            this.vendorInvoiceStateService.refreshNotPaidVendorInvoices(company.id, search, this.notPaidOffset),
          ])
        : isPaid
          ? this.vendorInvoiceStateService.refreshPaidVendorInvoices(company.id, search, this.paidOffset)
          : this.vendorInvoiceStateService.refreshNotPaidVendorInvoices(company.id, search, this.notPaidOffset),
    ),
  );

  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly vendorInvoiceSearchComponentService: VendorInvoiceSearchComponentService,
  ) {}
}
